import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "../actions/alertsTypes";

const initialState = {
  notifications: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      const newArray = [...state.notifications, action.payload];

      if (newArray.length > 3) {
        newArray.shift();
      }

      return {
        ...state,
        notifications: newArray,
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (item, index) => item.id !== action.payload
        ),
      };

    default:
      return state;
  }
}
