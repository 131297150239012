import { UserInterface } from "@ts/interfaces";
import { ChangeUserDataAction, ActionTypes } from "@services/redux/user";

const initialState: UserInterface = {
  username: "",
  email: "",
  firstName: "",
  lastName: "",
  activePhone: null,
  subscription: {
    activeFrom: "",
    activeTo: "",
    autoRenewal: false,
    billingCycle: "",
    displayName: "",
    displayDescription: "",
    isActive: false,
    name: "",
    options: {
      days: "",
      level: 0,
      maxResolution: "",
      mergeSupport: 0,
      scansPerDay: 0,
      stlSupport: 0,
      type: "subscription",
      videoLength: 0,
    },
  },
};

type Action = ChangeUserDataAction;

export default function (state = initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.CHANGE_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
