import {
  UPLOAD_ADD_SCAN,
  UPLOAD_REMOVE_SCAN,
  UPDATE_CURRENT_SELECTED,
  UPDATE_SCAN_UPLOAD_PROGRESS,
  ADD_FILE,
  REMOVE_FILE,
  UPDATE_FILE,
} from "../actions/uploadTypes";

const initialState = {
  scansUploading: {},
  currentSelected: {
    scan_name: "",
    scan_description: "",
    quality_template: "quality_low",
    file_instance: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPLOAD_ADD_SCAN:
      return {
        ...state,
        scansUploading: {
          ...state.scansUploading,
          [action.payload.key]: action.payload.value,
        },
      };
    case UPLOAD_REMOVE_SCAN:
      let newObject = { ...state.scansUploading };
      delete newObject[action.payload];

      return {
        ...state,
        scansUploading: newObject,
      };
    case UPDATE_SCAN_UPLOAD_PROGRESS:
      let updateWithScanProgress = { ...state.scansUploading };
      updateWithScanProgress[action.payload.key]["upload"] =
        action.payload.value;

      return {
        ...state,
        scansUploading: updateWithScanProgress,
      };
    case UPDATE_CURRENT_SELECTED:
      return {
        ...state,
        currentSelected: { ...state.currentSelected, ...action.payload },
      };
    case ADD_FILE:
      return {
        ...state,
        currentSelected: {
          ...state.currentSelected,
          file_instance: [
            ...state.currentSelected.file_instance,
            action.payload,
          ],
        },
      };
    case UPDATE_FILE:
      const newList = state.currentSelected.file_instance.map((item) => {
        if (item.name === action.payload.name) {
          return action.payload.value;
        }
        return item;
      });

      return {
        ...state,
        currentSelected: {
          ...state.currentSelected,
          file_instance: newList,
        },
      };
    default:
      return state;
  }
}
