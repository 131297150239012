import { combineReducers } from "redux";
import scansReducer from "./reducer/scansReducer";
import appReducer from "./reducer/appReducer";
import uploadReducer from "./reducer/uploadReducer";
import alertsReducer from "./reducer/alertsReducer";
import userReducer from "./user/userReducer";
import { authReducer } from "./auth/authReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  scans: scansReducer,
  app: appReducer,
  upload: uploadReducer,
  alerts: alertsReducer,
  user: userReducer,
});

export default rootReducer;
