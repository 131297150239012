import React from "react";

interface Props {
  [key: string]:
    | React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLOrSVGElement>,
        HTMLOrSVGElement
      >
    | any;
}

export const RotateEditorIcon: React.FC<Props> = ({ ...other }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 32 32"
      {...other}
    >
      <path d="M17.8 5.5c1.2-.7 2.3-1.3 3.5-1.7 3-1.1 5.5-1.1 6.7.2 1 1 1.2 2.9.6 5.4-.1.5.2 1.1.7 1.2.5.1 1.1-.2 1.2-.7.9-3.2.5-5.7-1.1-7.3-1.8-1.8-5-2-8.9-.6-1.5.6-3 1.4-4.6 2.3-1.5-1-3.1-1.8-4.6-2.3-3.9-1.5-7-1.3-8.9.6C-.1 5.2.8 10.4 4.3 16c-1 1.5-1.8 3.1-2.3 4.6-1.5 3.9-1.3 7 .6 8.9 1 1 2.5 1.5 4.2 1.5 1.4 0 2.9-.3 4.7-1 3.6-1.4 7.6-4 11.1-7.6.2-.2.3-.4.5-.5V24c0 .6.4 1 1 1s1-.4 1-1v-5c0-.6-.4-1-1-1h-5c-.6 0-1 .4-1 1s.4 1 1 1h3.1c-.3.4-.7.7-1 1.1-3.3 3.3-7 5.8-10.4 7.1-3.1 1.1-5.6 1.1-6.8-.2-1.2-1.2-1.3-3.7-.1-6.8.4-1.1 1-2.3 1.7-3.5 1 1.4 2.2 2.8 3.5 4.2H6c-.6 0-1 .4-1 1s.4 1 1 1h5c.6 0 1-.4 1-1v-5c0-.6-.4-1-1-1s-1 .4-1 1V20c-1.3-1.4-2.4-2.7-3.3-4.1 1.2-1.7 2.6-3.4 4.3-5.1 1.6-1.6 3.3-3.1 5.1-4.2 1.7 1.2 3.4 2.6 5.1 4.2 3.3 3.3 5.8 7 7.1 10.4 1.2 3.1 1.1 5.5-.1 6.8-1.3 1.3-4 1.3-7.3-.1-.5-.2-1.1 0-1.3.5-.2.5 0 1.1.5 1.3 2 .8 3.8 1.2 5.3 1.2 1.7 0 3.2-.5 4.2-1.5 3.6-3.6.6-12.4-7-19.9-1.6-1.5-3.2-2.8-4.8-4zm-8.3 4c-1.5 1.5-2.8 3.1-4 4.7C2.8 9.6 2.3 5.6 4 4c1.2-1.2 3.7-1.3 6.8-.1 1.1.4 2.3 1 3.5 1.7C12.6 6.7 11 8 9.5 9.5z" />
    </svg>
  );
};
