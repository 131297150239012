import React from "react";

interface Props {
  other?: object;
  [x: string]: any;
}

export const BrandIconSVG: React.FC<Props> = ({ ...other }) => {
  return (
    <svg
      {...other}
      viewBox="0 0 8.9031096 7.5109401"
      fill="none"
      version="1.1"
      id="svg11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath="url(#clip0_133_435)"
        id="g4"
        transform="translate(-0.00415039,-6.29456)"
      >
        <path
          d="M 0.181373,13.609 C 0.278845,13.5009 0.96547,12.7149 1.70721,11.8622 L 3.05582,10.312 h 2.71927 l 1.38893,1.5502 c 0.76391,0.8527 1.46865,1.6387 1.56609,1.7468 l 0.17715,0.1965 H 7.76106 6.61486 L 4.41965,11.3667 2.23258,13.8055 H 1.11837 0.00415039 Z M 2.90594,9.63383 C 2.77613,9.54223 0.02325545,6.2991312 0.02325545,6.2991312 L 2.20113,6.29723 4.32308,8.91593 6.67251,6.29456 8.8803462,6.29791 5.97544,9.5914988 l -3.0695,0.04233 z"
          fill="#000000"
          id="path2"
        />
      </g>
      <defs id="defs9">
        <clipPath id="clip0_133_435">
          <rect width="58" height="20" fill="#ffffff" id="rect6" x="0" y="0" />
        </clipPath>
      </defs>
    </svg>
  );
};
