import React from "react";

import "./styles.scss";
import { BrandIconSVG } from "./BrandIconSVG";
import classNames from "classnames";

interface Props {
  size: number;
  [x: string]: any;
}

export const BrandIcon: React.FC<Props> = ({ size, className, ...other }) => {
  const svgIconSizeRatio = 0.5; // The size ratio of the "x" in relation to complete size.

  const cssClass = classNames("c-logo", className);

  return (
    <div
      className={cssClass}
      style={{ width: `${size}px`, height: `${size}px` }}
      {...other}
    >
      <BrandIconSVG style={{ minWidth: `${size * svgIconSizeRatio}` }} />
    </div>
  );
};
