import dayjs, { type Dayjs } from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";

dayjs.extend(isToday);
dayjs.extend(isYesterday);

export const second = 1e3;
export const minute = 6e4;
export const hour = 36e5;
export const day = 864e5;
export const week = 6048e5;
export const month = 2592e6;
export const year = 31536e6;

export const convertUTCDateToLocalDate = (date: Date) => {
  const newDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000,
  );
  newDate.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};

export const dateFromString = (dateInt: number | string) => {
  dateInt = dateInt.toString();

  const year = parseInt(dateInt.slice(0, 4));
  const month = parseInt(dateInt.slice(4, 6)) - 1; // Subtracting one month since daysjs month are zero based.
  const day = parseInt(dateInt.slice(6, 8));
  const hours = parseInt(dateInt.slice(8, 10));
  const minutes = parseInt(dateInt.slice(10, 12));
  const seconds = parseInt(dateInt.slice(12, 14));

  const d = new Date(year, month, day, hours, minutes, seconds);
  return dayjs(convertUTCDateToLocalDate(d));
};

export const isoDate = (date?: Dayjs) => {
  return (date ? date : dayjs()).format("YYYYMMDDHHmmssSSS");
};

export const formatDate = (date: Dayjs, daysOnly = false) => {
  let formatString = "DD MMM YYYY";

  if (!daysOnly) formatString += ", HH:mm";

  return date.format(formatString);
};
