import { AiOutlineWarning, AiFillWarning, AiFillFolderOpen, AiFillBell, AiFillCheckCircle, AiOutlineCheckCircle, AiFillAndroid,AiOutlineApple, AiFillApple, AiOutlineAndroid, AiFillEye, AiOutlineEye, AiOutlineBell, AiOutlineFolderOpen, AiOutlineUser, AiFillHome, AiOutlineHome, AiOutlineArrowUp, AiOutlineLock, AiFillLock, AiOutlineHeart, AiFillHeart, AiOutlineThunderbolt, AiFillThunderbolt, AiOutlineInstagram, AiFillInstagram, AiOutlineYoutube, AiFillYoutube, AiOutlineRocket, AiFillRocket, AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

import { FaUser, FaBars, FaLayerGroup } from "react-icons/fa";

import { RiUploadCloud2Fill, RiUploadCloud2Line, RiDashboardFill, RiDashboardLine } from "react-icons/ri";

import { MdVideoLibrary, MdFileDownload, MdErrorOutline, MdError, MdOutlineForum, MdForum, MdOutlineEmail, MdMenuBook } from "react-icons/md";

import { GrFormClose, GrFacebookOption } from "react-icons/gr";

import { ImBlogger } from "react-icons/im";

import { HiArrowsExpand, HiShoppingCart, HiOutlineShoppingCart, HiOutlineEmojiSad, HiCube, HiOutlineCube } from "react-icons/hi";

import { BiLogOut, BiSupport } from "react-icons/bi";

import {  IoMdArrowDropdown, IoIosArrowDown,  IoMdPhonePortrait, IoIosHelpCircleOutline, IoIosHelpCircle, IoMdRefresh } from "react-icons/io";

import { BsTrashFill, BsTrash, BsCheck, BsLayers, BsLayersFill, BsLayersHalf, BsArrowBarRight, BsAlignBottom } from "react-icons/bs";

import { FiLayers, FiCheck } from "react-icons/fi";

import {FcCancel} from "react-icons/fc";

import {VscInspect} from "react-icons/vsc";

import { IconType } from "react-icons";

export interface IconListItem {
  name: string;
  filled: IconType | null;
  outlined: IconType | null;
}

export const iconsList: Array<IconListItem> = [
  {
    name: "expand",
    outlined: HiArrowsExpand,
    filled: HiArrowsExpand
  },
  {
    name: "align_bottom",
    outlined: BsAlignBottom,
    filled: BsAlignBottom,
  },
  {
    name: "layer_difference",
    outlined: BsLayersHalf,
    filled: BsLayersHalf,
  },
  { name: "minus", filled: AiOutlineMinus, outlined: AiOutlineMinus },
  { name: "plus", filled: AiOutlinePlus, outlined: AiOutlinePlus },
  { name: "arrow_bar", filled: BsArrowBarRight, outlined: BsArrowBarRight },
  {
    name: "eye-open",
    filled: AiFillEye,
    outlined: AiOutlineEye,
  },
  {
    name: "cube",
    filled: HiCube,
    outlined: HiOutlineCube,
  },
  {
    name: "refresh",
    filled: IoMdRefresh,
    outlined: IoMdRefresh,
  },
  {
    name: "checkmark",
    filled: FiCheck,
    outlined: FiCheck,
  },
  {
    name: "android",
    filled: AiFillAndroid,
    outlined: AiOutlineAndroid,
  },
  {
    name: "apple",
    filled: AiFillApple,
    outlined: AiOutlineApple,
  },
  {
    name: "support",
    filled: BiSupport,
    outlined: BiSupport,
  },
  {
    name: "email",
    filled: MdOutlineEmail,
    outlined: MdOutlineEmail,
  },
  {
    name: "sad-smiley",
    filled: HiOutlineEmojiSad,
    outlined: HiOutlineEmojiSad,
  },
  { name: "manual", filled: MdMenuBook, outlined: MdMenuBook },
  { name: "cancel", filled: FcCancel, outlined: FcCancel },
  { name: "inspect", filled: VscInspect, outlined: VscInspect },
  {
    name: "rocket",
    filled: AiFillRocket,
    outlined: AiOutlineRocket,
  },
  {
    name: "layer_2",
    filled: FaLayerGroup,
    outlined: FiLayers,
  },
  {
    name: "layer_1",
    filled: BsLayersFill,
    outlined: BsLayers,
  },
  {
    name: "thunder",
    filled: AiFillThunderbolt,
    outlined: AiOutlineThunderbolt,
  },
  {
    name: "check",
    filled: BsCheck,
    outlined: BsCheck,
  },
  {
    name: "youtube",
    filled: AiFillYoutube,
    outlined: AiOutlineYoutube,
  },
  {
    name: "instagram",
    filled: AiFillInstagram,
    outlined: AiOutlineInstagram,
  },
  {
    name: "facebook_f",
    filled: GrFacebookOption,
    outlined: GrFacebookOption,
  },
  {
    name: "arrow_traditional",
    filled: AiOutlineArrowUp,
    outlined: AiOutlineArrowUp,
  },
  { name: "lock", filled: AiFillLock, outlined: AiOutlineLock },
  { name: "help", filled: IoIosHelpCircle, outlined: IoIosHelpCircleOutline },
  {
    name: "phone",
    filled: IoMdPhonePortrait,
    outlined: IoMdPhonePortrait,
  },
  {
    name: "success",
    filled: AiFillCheckCircle,
    outlined: AiOutlineCheckCircle,
  },
  {
    name: "error",
    filled: MdError,
    outlined: MdErrorOutline,
  },
  {
    name: "arrow_modern",
    filled: IoIosArrowDown,
    outlined: IoIosArrowDown,
  },
  {
    name: "folder",
    filled: AiFillFolderOpen,
    outlined: AiOutlineFolderOpen,
  },
  {
    name: "heart",
    filled: AiFillHeart,
    outlined: AiOutlineHeart,
  },
  {
    name: "download",
    filled: MdFileDownload,
    outlined: MdFileDownload,
  },
  {
    name: "trash",
    filled: BsTrashFill,
    outlined: BsTrash,
  },
  {
    name: "bell",
    filled: AiFillBell,
    outlined: AiOutlineBell,
  },
  {
    name: "user",
    filled: FaUser,
    outlined: AiOutlineUser,
  },
  {
    name: "upload_cloud",
    filled: RiUploadCloud2Fill,
    outlined: RiUploadCloud2Line,
  },
  {
    name: "home",
    filled: AiFillHome,
    outlined: AiOutlineHome,
  },
  {
    name: "bars",
    filled: FaBars,
    outlined: FaBars,
  },
  {
    name: "dashboard",
    filled: RiDashboardFill,
    outlined: RiDashboardLine,
  },
  {
    name: "video_gallery",
    filled: MdVideoLibrary,
    outlined: MdVideoLibrary,
  },
  {
    name: "close",
    filled: GrFormClose,
    outlined: GrFormClose,
  },
  {
    name: "blog",
    filled: ImBlogger,
    outlined: ImBlogger,
  },
  {
    name: "forum",
    filled: MdForum,
    outlined: MdOutlineForum,
  },
  {
    name: "shopping_cart",
    filled: HiShoppingCart,
    outlined: HiOutlineShoppingCart,
  },
  {
    name: "logout",
    filled: BiLogOut,
    outlined: BiLogOut,
  },
  {
    name: "warning",
    filled: AiFillWarning,
    outlined: AiOutlineWarning,
  },
  {
    name: "arrow_triangle",
    filled: IoMdArrowDropdown,
    outlined: IoMdArrowDropdown,
  },
]
