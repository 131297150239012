import React from "react";

interface Props {
  [key: string]:
    | React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLOrSVGElement>,
        HTMLOrSVGElement
      >
    | any;
}

export const MergeScansIcon: React.FC<Props> = ({ ...other }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...other}>
      <path d="m8 17 4-4h3.2c.4 1.2 1.5 2 2.8 2 1.7 0 3-1.3 3-3s-1.3-3-3-3c-1.3 0-2.4.8-2.8 2H12L8 7V3H3v5h3l4.2 4L6 16H3v5h5v-4Z" />
    </svg>
  );
};
