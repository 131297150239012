import { AuthTypes } from "./authTypes";
import { AuthInitialState } from "./interfaces";
import { removeToken, saveToken } from "@services/localStore";

const initialState: AuthInitialState = {
  isAuthenticated: false,
  isAuthLoading: true,
  error: { displayText: "" },
};

export function authReducer(
  state = initialState,
  action: { type: AuthTypes; payload: any }
) {
  switch (action.type) {
    case AuthTypes.AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isAuthLoading: false,
        error: initialState.error,
      };
    case AuthTypes.AUTH_REAUTH:
      saveToken("access", action.payload.access);
      saveToken("refresh", action.payload.refresh);

      return {
        ...state,
        isAuthenticated: true,
        isAuthLoading: false,
        error: initialState.error,
      };
    case AuthTypes.AUTH_LOADING:
      return {
        ...state,
        isAuthLoading: action.payload,
      };
    case AuthTypes.AUTH_LOGOUT:
      removeToken("refresh");
      removeToken("access");
      return {
        ...state,
        isAuthLoading: false,
        isAuthenticated: false,
        error: initialState.error,
      };
    case AuthTypes.AUTH_ERROR_RESET: {
      return {
        ...state,
        error: initialState.error,
      };
    }
    case AuthTypes.AUTH_ERROR:
      removeToken("refresh");
      removeToken("access");

      return {
        ...state,
        isAuthenticated: false,
        isAuthLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
