import React from "react";

import { ReactIconTypes } from "./types";
import classNames from "classnames";

import "./styles.scss";

import { iconsList } from "./icons-list";

const getIcon = (name: string) => {
  const Icon = iconsList.find((i) => i.name === name);

  if (!Icon) {
    throw new Error(`No react icon with name ${name} found.`);
  }

  return Icon;
};

interface Props extends React.HTMLAttributes<HTMLElement> {
  icon: ReactIconTypes;
  type?: "filled" | "outlined";
  className?: string;
  size?: "s" | "m" | "l";
  color?: "error" | "warning" | "success";
}

export const ReactIcons: React.FC<Props> = ({
  icon,
  type = "filled",
  className,
  size = "m",
  color,
  ...other
}) => {
  const Icon: any = getIcon(icon);

  const iconClass = classNames("c-icon", `c-icon--${size}`, className, {
    [`color-${color}`]: color,
  });

  if (type === "outlined" && Icon.outlined) {
    return <Icon.outlined className={iconClass} {...other} />;
  }
  if (type === "filled" && Icon.filled) {
    return <Icon.filled className={iconClass} {...other} />;
  }

  throw new Error(`React icon "${icon} has no type "${type}".`);
};
