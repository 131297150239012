import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";
// @ts-ignore
import rootReducer from "./rootReducer";

const initialState = {};
const middleWare = [thunk];

//logger
if (process.env.USE_DEBUG && process.env.USE_DEBUG.toString() === "1") {
  middleWare.push(require("redux-logger").default);
}

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleWare))
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
