import React, { forwardRef } from "react";
import { NotificationPropsInterface } from "./NotificationSystem";
import classNames from "classnames";
import { ReactIcons } from "@core-ui/icons";

interface Props extends NotificationPropsInterface {
  ref?: any;
  onClick?: (e: React.MouseEvent) => void;
  styles?: React.CSSProperties;
}

export const Notification: React.FC<Props> = forwardRef<
  HTMLDivElement | null,
  React.PropsWithChildren<Props>
>(({ type, title, text, onClick, styles }, ref) => {
  const cssClass = classNames("c-notification", `c-notification--${type}`);

  return (
    <div className={cssClass} ref={ref} onClick={onClick} style={styles}>
      <div className="mr-s c-notification__icon-wrapper">
        <ReactIcons className="c-notification__icon" icon={type} size="l" />
      </div>
      <div>
        {title && (
          <p className="subtitle-2 font- mb-xs c-notification__title">
            {title}
          </p>
        )}
        <p className="caption c-notification__text">{text}</p>
      </div>
    </div>
  );
});
