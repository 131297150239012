import React from "react";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/700.css";

import { Provider } from "react-redux";
import store from "./src/services/redux/store";
import { NotificationProvider } from "./src/features/notifications";

import "./src/global.css";
import "./src/services/i18next/i18n";

//export const wrapRootElement = wrapWithProvider;

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <NotificationProvider>{element}</NotificationProvider>
    </Provider>
  );
};
