import {
  APP_LOADED_ERROR,
  APP_LOADED_SUCCESSFUL,
  APP_LOADING,
} from "../actions/appTypes";

const initialState = {
  isAppLoading: true,
  isAppLoaded: false,
  error: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case APP_LOADING:
      return {
        ...state,
        isAppLoading: true,
        isAppLoaded: false,
      };
    case APP_LOADED_SUCCESSFUL:
      return {
        ...state,
        isAppLoading: false,
        isAppLoaded: true,
      };
    case APP_LOADED_ERROR:
      return { ...state, isAppLoading: false, isAppLoaded: false };

    default:
      return state;
  }
}
