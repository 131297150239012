import { Dispatch } from "redux";
import { UserInterface } from "@ts/interfaces";
import { ActionTypes } from "@services/redux/user/actionTypes";

export const changeUserData = (data: Partial<UserInterface>) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionTypes.CHANGE_USER_DATA,
      payload: data,
    });
  };
};
