import { useState } from "react";
import { NotificationPropsInterface } from "./NotificationSystem";
import { nowGuid } from "@services/utils";

export interface NotificationInterface extends NotificationPropsInterface {
  id: string;
}

interface PostNotificationInterface {
  duration?: number;
}

export interface UseNotificationsInterface {
  notifications: NotificationInterface[];
  addNotification: (
    notification: NotificationPropsInterface,
    options?: PostNotificationInterface
  ) => Promise<string>;
  removeNotification: (id: string) => void;
}

export const useNotifications = (): UseNotificationsInterface => {
  const DEFAULT_NOTIFICATION_DISPLAY_TIME = 6000;

  const [notifications, setNotifications] = useState<NotificationInterface[]>(
    []
  );

  /**
   * Adds a new notification to the screen.
   *
   * @param data - Props of Notification Component
   * @param options - Optional settings for display duration etc.
   */
  const addNotification = async (
    data: NotificationPropsInterface,
    options?: PostNotificationInterface
  ) => {
    options = {
      ...options,
      duration: options?.duration
        ? options.duration
        : DEFAULT_NOTIFICATION_DISPLAY_TIME,
    };
    const generatedID = nowGuid();
    setNotifications((prevState) => [
      ...prevState,
      { ...data, id: generatedID },
    ]);

    async function remove(timeout: number) {
      setTimeout(() => {
        removeNotification(generatedID);
      }, timeout);
    }

    if (options?.duration) await remove(options.duration);

    return generatedID;
  };

  /**
   * Removes a notification by a given ID from the screen.
   * @param id - Notification ID (received after adding a notification)
   */
  const removeNotification = (id: string) => {
    setNotifications((prevState) => prevState.filter((i) => i.id !== id));
  };

  return { notifications, addNotification, removeNotification };
};
