import React, { useContext } from "react";
import {
  useNotifications as notificationHook,
  UseNotificationsInterface,
} from "./useNotifications";

const NotificationContext = React.createContext<any>(null);

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const NotificationProvider: React.FC<Props> = ({ children }) => {
  const hook = notificationHook();

  return (
    <NotificationContext.Provider value={{ ...hook }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = (): UseNotificationsInterface => {
  const ctx = useContext(NotificationContext);

  if (ctx === undefined) {
    throw new Error(
      "useNotificationsContext must be used within a NotificationContext provider."
    );
  }

  return ctx;
};
