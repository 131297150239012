import dayjs from "dayjs";
import { isoDate } from "@services/formatting";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";
import { resolveSrv } from "dns";

export const formatStatusCode = (statusCode: number) => {
  const statusMessages: { [key: number]: string } = {
    0: "success",
    [-1]: "processing",
    1: "error",
    2: "error",
  };

  return statusMessages[statusCode];
};

/**
 * Create's our common GUID format from the current date timestamp + a uuid4 generated string.
 */
export const nowGuid = () => {
  return isoDate().slice(0, -1) + uuidv4();
};

/**
 * Capitalizes the first letter of a string.
 * @param input - String to capitalize.
 */
export const capitalizeFirstLetter = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const generateRandomNumbers = (
  min: number,
  max: number,
  places: number,
) => {
  // If both the minimum and maximum values are integers, return a random integer. Don't let the user specify any decimal places.
  if (Number.isInteger(min) && Number.isInteger(max)) {
    if (places !== undefined) {
      new Error("Cannot specify decimal places with integers.");
    }
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Otherwise, return a random floating point number with specified decimal places.
  else {
    // Make sure the minimum value is a number.
    if (Number.isNaN(Number.parseFloat(String(min)))) {
      new Error("Minimum value is not a number.");
    }

    // Make sure the maximum value is a number.
    if (Number.isNaN(Number.parseFloat(String(max)))) {
      new Error("Maximum value is not a number.");
    }

    // Make sure the decimal places value is a non-negative number greater than 0.
    if (!Number.isInteger(places)) {
      new Error("Number of decimal places is not a number.");
    }

    if (places <= 0) {
      new Error("Number of decimal places must be at least 1.");
    }

    // Generate the floating point number.
    let value = Math.random() * (max - min + 1) + min;
    return Number.parseFloat(String(value)).toFixed(places);
  }
};

/**
 * Returns the file extension from a string.
 * If file has no extension, it throw's an error.
 *
 * @param filename <string> filename as string.
 * @return file extension <string>
 */
export const extractFileExtension = (filename: string): string => {
  if (!filename.includes(".")) {
    throw `Cant extract file extension from ${filename}. No extension found`;
  }

  const split = filename.split(".");

  return split[split.length - 1];
};

export const blobToText = async (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.readAsText(blob);
  });

/**
 * @description
 * Convert an Asset File to object URI for better performance
 * - A user can use this url as a resource url
 *      - For example it can be used as src of image, video tag.
 *
 * Refer to this link for more detailed information
 * https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
 *
 * @param {File} file  The video file
 * @param {boolean} revoke If true the object uri will be removed after its creation
 * @returns {string} window object url ex. https://blob:video58699
 *
 */
export const importFileandPreview = (
  file: File,
  revoke?: boolean,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    let preview = createObjectURL(file);
    // remove reference
    if (revoke && preview) {
      window.URL.revokeObjectURL(preview);
    }
    setTimeout(() => {
      resolve(preview || "");
    }, 100);
  });
};

export function createObjectURL(file: File) {
  if (window.webkitURL) {
    return window.webkitURL.createObjectURL(file);
  } else if (window.URL && window.URL.createObjectURL) {
    return window.URL.createObjectURL(file);
  } else {
    return null;
  }
}

export function revokeObjectURL(url: string) {
  if (window.webkitURL) {
    return window.webkitURL.revokeObjectURL(url);
  } else if (window.URL && window.URL.revokeObjectURL) {
    return window.URL.revokeObjectURL(url);
  } else {
    return null;
  }
}

/**
 * @ref - https://stackoverflow.com/questions/23640869/create-thumbnail-from-video-file-via-file-input
 *
 * @param file
 * @param secondsToExtract
 * @param {number} [quality=1.0] - The quality of the image to output
 * @returns {string} base64 image string
 */
export const getVideoCovers = (
  file: File,
  secondsToExtract: number[],
  quality = 1.0,
): Promise<string[]> => {
  return new Promise(async (resolve, reject) => {
    const startTime = dayjs();

    let urlOfFile: any = await importFileandPreview(file);

    let seekIdx = 0;
    const extracted: string[] = [];

    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.preload = "metadata";
    videoPlayer.src = urlOfFile;
    videoPlayer.playsInline = true;
    videoPlayer.autoplay = true;
    videoPlayer.muted = true;

    videoPlayer.crossOrigin = "anonymous";

    videoPlayer.addEventListener("error", (ex) => {
      console.log(ex);
      reject(`error when loading video file ${ex}`);
    });

    videoPlayer.addEventListener("seeked", () => {
      // define a canvas to have the same dimension as the video
      const canvas = document.createElement("canvas");
      canvas.width = videoPlayer.videoWidth;
      canvas.height = videoPlayer.videoHeight;
      // draw the video frame to canvas
      const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
      ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

      const imageDataURL = ctx.canvas.toDataURL("image/jpeg", quality);

      extracted.push(imageDataURL === "data:," ? "" : imageDataURL);

      if (extracted.length === secondsToExtract.length) {
        console.log("Video covers took", dayjs().diff(startTime) + " ms");

        resolve(extracted);

        // Trying to remove all references from the file to prevent safari from crashing after 3-5 uploads.
        urlOfFile = null;

        revokeObjectURL(urlOfFile);
        videoPlayer.pause();
        videoPlayer.removeAttribute("src");
        videoPlayer.remove();

        canvas.remove();
        return;
      }

      seekIdx++;
      videoPlayer.currentTime = secondsToExtract[seekIdx];
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      setTimeout(() => {
        videoPlayer.currentTime = secondsToExtract[seekIdx];
      }, 200);
    });
  });
};

/**
 * Extracts the video duration from a video file and returns its value.
 * @param file - The video file to get the duration from.
 */
export const getTimeOfVideo = (file: File): Promise<number> =>
  new Promise(async (resolve, reject) => {
    let urlOfFile: any = await createObjectURL(file);

    const videoPlayer = document.createElement("video");
    videoPlayer.preload = "metadata";

    videoPlayer.addEventListener("loadedmetadata", () => {
      revokeObjectURL(urlOfFile);
      return resolve(videoPlayer.duration);
    });

    videoPlayer.addEventListener("error", (ex) => {
      reject(`Could not extract video length from ${file.name}`);
    });

    videoPlayer.src = urlOfFile;
  });

export async function dataUrlToFile(
  dataUrl: string,
  fileName: string,
): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: "image/jpeg" });
}

export function dataURItoBlob(dataURI: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

/**
 * Get's the absolute height of the given element including borders like margin, padding etc.
 * @param el - Element in the document.
 */
export const getAbsoluteHeight = (el: any) => {
  el = typeof el === "string" ? document.querySelector(el) : el;

  const styles = window.getComputedStyle(el);
  const margin =
    parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"]);

  return Math.ceil(el.offsetHeight + margin);
};

/**
 * Work's like the builtin arr.find() function but returns the item and the index the item was found at as a tuple.
 * If no item was found, the function returns null.
 *
 * @param array - The array to find the item in.
 * @param compareFun - The compare function used to find the item.
 */
export const findWithIndex = <T>(
  array: T[],
  compareFun: (item: T) => boolean,
): [number, T] | null => {
  let idx = 0;

  for (let i = 0; i < array.length; i++) {
    if (compareFun(array[i])) return [idx, array[i]];

    idx++;
  }

  return null;
};

/**
 * Get's a cookie by it's name and returns it's value.
 * @param name - Name of the cookie.
 */
export const getCookie = (name: string) => {
  function escape(s: string) {
    return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, "\\$1");
  }
  const match = document.cookie.match(
    RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)"),
  );
  return match ? match[1] : null;
};

/**
 * Downloads a given blob inside the browser.
 * @param blob - Blob Data
 * @param name - The final name the file should have when donwloaded.
 */
export const downloadBlob = (blob: Blob, name: string) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
};

/**
 * Convert's a base 64 string to a javascript blob file.
 *
 * @param base64 - string to convert
 * @param contentType - content type which should be available through some kind of server response.
 */
export const base64ToBlob = (base64: string, contentType: string) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], {
    type: contentType,
  });
};
