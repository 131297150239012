import jwtDecode, { JwtPayload } from "jwt-decode";
import dayjs from "dayjs";

export const saveToken = (name: string, value: string) => {
  if (typeof window === "undefined") return;

  if (!value) {
    throw Error(
      `Cant save token. You passed an empty string. Use removeToken() if you want to delete a certain token`
    );
  }

  localStorage.setItem(name, value);
};

export const getLocalTokens = () => {
  if (typeof window === "undefined") {
    return { access: null, refresh: null };
  }

  const access = localStorage.getItem("access");
  const refresh = localStorage.getItem("refresh");

  return {
    access: access,
    refresh: refresh,
  };
};

export const removeToken = (name: string) => {
  if (typeof window === "undefined") return;

  localStorage.removeItem(name);
};

export const isTokenExpired = (token: string) => {
  const user: JwtPayload = jwtDecode(token);

  if (!user.exp) return true;

  return dayjs.unix(user.exp).diff(dayjs()) < 1;
};

export const isTokenValidFormat = (token: string) => {
  try {
    jwtDecode(token);
    return true;
  } catch {
    return false;
  }
};
