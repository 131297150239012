export const isRecurringUser = () => {
  if (typeof window === "undefined") return;

  const isRecurring = localStorage.getItem("is_recurring_user");

  if (!(isRecurring === "1")) {
    localStorage.setItem("is_recurring_user", "1");
    return false;
  }

  return true;
};

export const getLastVisit = () => {
  if (typeof window === "undefined") return;

  return localStorage.getItem("last_visit");
};

export const setStorageLastVisit = (value: string) => {
  if (typeof window === "undefined") return;

  return localStorage.setItem("last_visit", value);
};

export const getBooleanFromLocalStorage = (key: string) => {
  if (typeof window === "undefined") return;

  const valueToCheck = localStorage.getItem(key);

  return valueToCheck === "1";
};

export const saveBooleanToLocalStorage = (key: string, value: boolean) => {
  return localStorage.setItem(key, value ? "1" : "0");
};
