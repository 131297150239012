exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activate-tsx": () => import("./../../../src/pages/activate.tsx" /* webpackChunkName: "component---src-pages-activate-tsx" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app/[...].js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-editor-tsx": () => import("./../../../src/pages/editor/[...].tsx" /* webpackChunkName: "component---src-pages-editor-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legolizer-tsx": () => import("./../../../src/pages/legolizer/[...].tsx" /* webpackChunkName: "component---src-pages-legolizer-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-send-reset-password-tsx": () => import("./../../../src/pages/send-reset-password.tsx" /* webpackChunkName: "component---src-pages-send-reset-password-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */)
}

