import {
  SCANS_ADD,
  RECENT_SCANS_ADD,
  FAVORITE_SCANS_ADD,
  RUNNING_SCANS_ADD,
  INITIAL_LOAD_SUCCESS,
} from "../actions/scansTypes";

const initialState = {
  scansList: [],
  recentScans: [],
  favoriteScans: [],
  runningScans: [],
  isLoadingInitial: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SCANS_ADD:
      return {
        ...state,
        scanList: [...state.scanList, ...action.payload],
      };
    case RECENT_SCANS_ADD:
      return {
        ...state,
        recentScans: [...state.recentScans, ...action.payload],
      };
    case FAVORITE_SCANS_ADD:
      return {
        ...state,
        favoriteScans: [...state.favoriteScans, ...action.payload],
      };
    case RUNNING_SCANS_ADD:
      return {
        ...state,
        runningScans: [...state.runningScans, ...action.payload],
      };
    case INITIAL_LOAD_SUCCESS:
      return {
        ...state,
        isLoadingInitial: false,
      };
    default:
      return state;
  }
}
